<template>
  <div id="map-wrap" style="height: 400px; z-index:0">
    <l-map :zoom="15" :center="[-23.2540041, -45.9316772, 17]">
      <l-tile-layer
        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-marker :lat-lng="[-23.2540041, -45.9316772, 17]">
        <l-popup
          :content="content"
          :options="{ autoClose: false, closeOnClick: false }"
        />
      </l-marker>
    </l-map>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: `
        <p>
          <strong>Rua sete lagos, 379</strong> <br>
          Chácaras Reunidas <br>
          CEP: 12.238-510 <br>
          <a href="https://www.google.com/maps/?q=Rua sete lagos, 379"> Abrir no Google Maps </a>
        </p>
      `
    };
  }
};
</script>
