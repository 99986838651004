<template>
  <v-app>
    <v-row>
      <h1 class="mx-auto mt-4 mb-2">Entre em contato conosco:</h1>
    </v-row>
    <v-row
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-row v-if="!loading">
      <v-col class="col-12 col-lg-4 mx-auto">
        <Informations :contacts="contacts" v-if="contacts.length > 0" />
      </v-col>
    </v-row>
    <h1 class="mx-auto mt-2 mb-2">Onde nos localizamos?</h1>
    <Map />
  </v-app>
</template>
<script>
import Map from "@/components/map.vue";
import Informations from "@/components/informations.vue";
import { RepositoryFactory } from "@/repositories/factory";

const ContentRepository = RepositoryFactory.get("contents");

export default {
  name: "App",
  components: {
    Map,
    Informations
  },
  data() {
    return {
      contacts: null,
      loading: false
    };
  },
  created() {
    this.$store.dispatch('insertCount');
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      ContentRepository.findContacts()
        .then(({ data }) => {
          this.contacts = data;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
