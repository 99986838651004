<style scoped>
.v-list-item__title {
  font-size: 0.769rem !important;
}
</style>
<template>
  <div class="mt-2">
    <v-card>
      <v-list dense rounded>
        <v-list-item v-for="(contact, i) in contacts" :key="i" link>
          <v-list-item-icon>
            <v-icon>{{ handleType(contact.tipo).icon }}</v-icon>
          </v-list-item-icon>
          <div max-width="100%">
            <v-list-item-content>
              <v-list-item-title>
                <a :href="`${handleType(contact.tipo).href}${contact.texto}`">
                  {{ contact.texto_formatado }}
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    contacts: {
      type: Array
    }
  },
  methods: {
    handleType(type) {
      const types = {
        email: {
          icon: "mail",
          href: "mailto:"
        },
        celular: {
          icon: "mobile_screen_share",
          href: "tel:"
        },
        telefone: {
          icon: "phone",
          href: "tel:"
        },
        telefoneRepresentant: {
          icon: "phone",
          href: "tel:"
        },
        emailRepresentant: {
          icon: "mail",
          href: "mailto:"
        },
        default: {
          icon: "mail",
          href: "mailto"
        }
      };

      return types[type] || types["default"];
    }
  }
};
</script>
